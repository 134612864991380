import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';

import swal from 'sweetalert';
import Modal from 'react-modal';

import clientData from './inc/clientData';
import config from './inc/config';
import {randomUUID} from './inc/compat';
import {getCookie, setCookie} from './inc/cookies';

import AnonymousFrame from './frame/AnonymousFrame';
import UserFrame from './frame/UserFrame';

import ActiveAdverts from './pages/adverts/ActiveAdverts';
import AccountCancellation from './pages/account/AccountCancellation';
import AccountInfo from './pages/account/AccountInfo';
import Adverts from './pages/adverts/Adverts';
import AdvertEdit from './pages/adverts/AdvertEdit';
import ChangePassword from './pages/account/ChangePassword';
import Consultants from './pages/account/Consultants';
import ConsultantAdverts from './pages/account/ConsultantAdverts';
import FavoriteAdverts from './pages/favorites/FavoriteAdverts';
import FavoriteAdvertDetail from './pages/favorites/FavoriteAdvertDetail';
import FavoriteSellers from './pages/favorites/FavoriteSellers';
import FavoriteSearches from './pages/favorites/FavoriteSearches';
import Messages from './pages/messages/Messages';
import MessageDetails from './pages/messages/MessageDetails';
import NotFoundPage from './pages/NotFoundPage';
import PassiveAdverts from './pages/adverts/PassiveAdverts';
import PendingAdverts from './pages/adverts/PendingAdverts';
import Products from './pages/products/Products';
import PostAdvert from './pages/adverts/PostAdvert';
import RemainingProductDetails from './pages/account/RemainingProductDetails';
import AccountSummary from '../src/pages/AccountSummary';

import '../src/assets/css/index.css';
import SelectConsultant from './pages/adverts/SelectColsultant';
import MessageCreate from './pages/messages/MessageCreate';
import SelectConsultantPoint from './pages/adverts/SelectConsultantPoint';
import CreateAdvert from './pages/adverts/CreateAdvert';
import MailValidation from './pages/validations/MailValidation';
import IdentityValidationSuccess from './pages/validations/IdentityValidationSuccess';
import IdentityValidationFailed from './pages/validations/IdentityValidationFailed';
import IdentityValidation from './pages/validations/IdentityValidation';
import PhoneValidation from './pages/validations/PhoneValidation';
import InsuranceTransactions from './pages/insurance/InsuranceTransactions';

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    console.log('Index: componentDidMount()');
    this.init();
  }

  componentWillUnmount() {
    console.log('Index: componentWillUnmount()');
  }

  init() {
    clientData.deviceId = getCookie('deviceId', randomUUID());
    clientData.sessionHash = getCookie('sessionHash', '');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({
        deviceId: clientData.deviceId,
        deviceManufacturer: '',
        deviceModel: '',
        osLanguage: '',
        osVersion: '',
        versionCode: '',
        versionName: '',
        platform: 'web',
        token: '',
        timezone: '+03:00',
        userAgent: navigator.userAgent
      })
    };

    fetch(config.restApi + 'init', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          clientData.sessionHash = responseData.sessionHash;
          clientData.sessionType = responseData.sessionType;

          setCookie('deviceId', clientData.deviceId, 31536000);
          setCookie('sessionType', clientData.sessionType, 31536000);

          clientData.sessionType === 'anonymous' && setCookie('sessionHash', clientData.sessionHash, 31536000);

          this.start(responseData);
        break;
        default:
          swal({dangerMode: true, icon: 'error', title: 'Hay Aksi', text: responseData.message, buttons: 'Yeniden Dene'}).then(() => this.init());
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Yükle'}).then(() => this.init()));
  }

  start(_responseData) {
    this.setState({isLoading: false});
  }

  render() {
    clientData.sessionType = getCookie('sessionType', '');

    return this.state.isLoading ?
      <div className="ibox-content sk-loading gray-bg w-100 h-100">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      clientData.sessionType === 'member' ?
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<UserFrame><AccountSummary/></UserFrame>} />
          <Route path="/activeAdverts" element={<UserFrame><ActiveAdverts/></UserFrame>} />
          <Route path="/accountCancellation" element={<UserFrame><AccountCancellation/></UserFrame>} />
          <Route path="/accountInfo" element={<UserFrame><AccountInfo/></UserFrame>} />
          <Route path="/adverts" element={<UserFrame><Adverts /></UserFrame>} />
          <Route path="/advertEdit" element={<UserFrame><AdvertEdit /></UserFrame>} />
          <Route path="/changePassword" element={<UserFrame><ChangePassword/></UserFrame>} />
          <Route path="/consultants" element={<UserFrame><Consultants/></UserFrame>} />
          <Route path="/consultantAdverts" element={<UserFrame><ConsultantAdverts/></UserFrame>} />
          <Route path="/favoriteAdverts" element={<UserFrame><FavoriteAdverts/></UserFrame>} />
          <Route path="/favoriteAdvertDetail" element={<UserFrame><FavoriteAdvertDetail/></UserFrame>} />
          <Route path="/favoriteSellers" element={<UserFrame><FavoriteSellers /></UserFrame>} />
          <Route path="/favoriteSearches" element={<UserFrame><FavoriteSearches /></UserFrame>} />
          <Route path="/identityValidation" element={<UserFrame><IdentityValidation /></UserFrame>} />
          <Route path="/identityValidation/success" element={<UserFrame><IdentityValidationSuccess /></UserFrame>} />
          <Route path="/identityValidation/failed" element={<UserFrame><IdentityValidationFailed /></UserFrame>} />
          <Route path="/insuranceTransactions" element={<UserFrame><InsuranceTransactions /></UserFrame>} />
          <Route path="/mailValidation" element={<UserFrame><MailValidation /></UserFrame>} />
          <Route path="/messages" element={<UserFrame><Messages /></UserFrame>} />
          <Route path="/messageDetails" element={<UserFrame><MessageDetails /></UserFrame>} />
          <Route path="/messageCreate" element={<UserFrame><MessageCreate /></UserFrame>} />
          <Route path="/passiveAdverts" element={<UserFrame><PassiveAdverts /></UserFrame>} />
          <Route path="/pendingAdverts" element={<UserFrame><PendingAdverts /></UserFrame>} />
          <Route path="/phoneValidation" element={<UserFrame><PhoneValidation /></UserFrame>} />
          <Route path="/postAdvert" element={<UserFrame><PostAdvert /></UserFrame>} />
          <Route path="/remainingProductDetails" element={<UserFrame><RemainingProductDetails /></UserFrame>} />
          <Route path="/createAdvert" element={<UserFrame><CreateAdvert /></UserFrame>} />
          <Route path="/selectConsultant" element={<UserFrame><SelectConsultant /></UserFrame>} />
          <Route path="/selectConsultantPoint" element={<UserFrame><SelectConsultantPoint /></UserFrame>} />
          <Route path="/products" element={<UserFrame><Products /></UserFrame>} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
      :
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AnonymousFrame />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
  }
}

const appElement = document.getElementById('root');
Modal.setAppElement(appElement);
ReactDOM.createRoot(appElement).render(<Index />);