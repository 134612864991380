import React from 'react';
import swal from 'sweetalert';
import {Link} from 'react-router-dom';

import clientData from '../../inc/clientData';
import config from '../../inc/config';
import AddedConsultantModal from '../../components/AddedConsultantModal';
import Images from '../../utils/Images';

class InsuranceTransactionsModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      insuranceLink: '',
      warningMessage: ''
    };
  }

  componentDidMount() {
    this.getInsuranceLink();
    window.scrollTo(0, 0);
    console.log("Consultants: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("Consultants: componentWillUnmount()");
  }

  getInsuranceLink = () => {
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    this.setState({isLoading: true})

    fetch(config.restApi + 'insuranceLink/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      this.setState({isLoading: false})

      switch (responseData.status) {
        case 200:
          this.state.insuranceLink = responseData.insuranceLink;
        break;
        default:
          this.state.warningMessage = responseData.message
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet basadadğlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <h3 className="text-darkblue">Sigorta İşlemleri</h3>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="row">
          <div className="col my-2">
            {this.state.warningMessage ?
              <span>{this.state.warningMessage}</span>
            :
              <div className='col-12'>
                <span>Sigorta ekranlarını başlatmak için aşağıdaki butonu kullanabilirsiniz.</span>
                <ul className='insuranceLink p-0 my-2'><li><a className="btn border btn-darkgray bg-orange-btn text-black" href={`${this.state.insuranceLink}`} target="_blank"><i className={"fa fa-angle-double-right"}></i> <span className="text-darkblue">Sigorta Ekranlarını Başlat</span></a></li></ul>
              </div>
            }

          </div>
        </div>
      </div>
  }
}

export default function InsuranceTransactions() {
  return (
    <InsuranceTransactionsModule />
  )
}